import type { ImageLoader, ImageProps } from 'next/image'
import { forceNumber } from '~/types/strict-null-helpers'

export function extractSource(src: ImageProps['src']) {
	if (typeof src === 'object') {
		if ('src' in src) {
			return src.src
		}
		if ('default' in src) {
			return src.default.src
		}
	}

	return src
}

export const scene7Loader: ImageLoader = ({ quality, src, width }) => {
	const url = new URL(src)

	url.searchParams.set('qlt', `${quality ?? 75}`)
	// TODO: determine how best to implement the webp fallback when targeting avif
	// url.searchParams.set('fmt', 'avif')

	const wid = Number(url.searchParams.get('wid') ?? 0)

	// Only adjust size if incoming width is smaller
	if (width < wid) {
		const hei = Number(url.searchParams.get('hei') ?? 0)
		const height = Math.round(width * (hei / wid))

		url.searchParams.set('wid', `${width}`)
		url.searchParams.set('hei', `${height}`)
		url.searchParams.set('size', `${width},${height}`)
	}

	return url.toString()
}

export const scene7LoaderWithOptimization: ImageLoader = ({ src, width }) => {
	const url = new URL(src)

	let derivedAspectRatio = 0
	let derivedSize = ''
	let derivedHeight = 0
	const paramWidth = forceNumber(url.searchParams.get('wid'))
	const paramHeight = forceNumber(url.searchParams.get('hei'))
	const paramSize = url.searchParams.get('size')

	// Derive the aspect ratio and height from current image URL
	if (paramWidth && paramHeight) {
		derivedAspectRatio = paramWidth / paramHeight
		derivedHeight = Math.round(width / derivedAspectRatio)
	}

	// Derive the scaled size param from current image URL
	if (paramWidth && paramHeight && paramSize) {
		const sizes = paramSize.split(',')
		const sizeScaleX = forceNumber(sizes[0]) / paramWidth
		const sizeScaleY = forceNumber(sizes[1]) / paramHeight
		derivedSize = `${Math.round(sizeScaleX * width)},${Math.round((sizeScaleY * width) / derivedAspectRatio)}`
	}

	// Assign explicit width and fit for srcset generation
	url.searchParams.set('wid', `${width}`)

	// If the aspect ratio can be derived, then set explicit height
	if (derivedAspectRatio) {
		url.searchParams.set('hei', derivedHeight.toString())
	}

	// If the scaled size can be derived, then set explicit size
	if (derivedSize) {
		url.searchParams.set('size', derivedSize)
	}

	return url.toString()
}

export function getLoader(src: ImageProps['src'], optimized = false): ImageLoader | undefined {
	const source = extractSource(src)

	if (source.includes('scene7') || source.includes('loremflickr')) {
		return optimized ? scene7LoaderWithOptimization : scene7Loader
	}
	return undefined
}

export function getImageSizes() {
	return [250, 425, 640, 750, 828, 1080, 1200, 2048, 3840] as const
}

export function getSrcSet(src: ImageProps['src']) {
	const isString = typeof src === 'string'
	if (!isString || (isString && !src.startsWith('http'))) return undefined
	const sources: string[] = []
	getImageSizes().forEach((size) => {
		sources.push(`${scene7LoaderWithOptimization({ src, width: size })} ${size}w`)
	})
	return sources.join(', ')
}
